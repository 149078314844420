<template>
  <b-card
    :title="title"
    class="multi-sig-card"
  >
    <div
      v-if="loading"
      class="text-center my-2 minimum-height loader"
    >
      <b-row>
        <b-col class="text-center">
          <b-spinner class="align-middle" />
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <b-badge
        :variant="displayedVariant"
        class="status-badge"
        href="#"
      >
        {{ deploymentStatus.toLocaleUpperCase() }}
      </b-badge>
      <b-card-sub-title class="mt-1 mb-1 card-sub">
        <span>Address: {{ displayedAddress }}</span>
        <span>Threshold: {{ threshold }}</span>
      </b-card-sub-title>
      <b-card-header class="border-bottom-1 mb-3" />
      <cp-tabular-headers
        :columns="multiSigHeaders"
        class="mb-3"
      />
      <cp-input-container
        ref="multiSigForm"
        class="form-scroll"
      >
        <b-table
          :fields="multiSigFields"
          :items="owners"
          hover
          striped
        >
          <template
            slot="address"
            slot-scope="{item}"
          >
            <cp-input
              v-model="item.address"
              :is-readonly="isReadOnly"
              class="input"
              name="address"
              validate="required"
            />
          </template>
          <template
            slot="comment"
            slot-scope="{item}"
          >
            <cp-input
              v-model="item.comment"
              :is-readonly="isReadOnly"
              class="input"
              name="comment"
            />
          </template>
          <template
            slot="actions"
            slot-scope="{index}"
          >
            <span
              v-if="index >= threshold && !isReadOnly"
            >
              <i
                class="ion ion-ios-remove-circle remove-icon"
                @click="removeOwner(index)"
              />
            </span>
          </template>
        </b-table>
      </cp-input-container>
      <b-card-footer
        v-if="!isReadOnly"
        class="border-top-1 mt-5 card-footer-align"
      >
        <cp-button
          size="lg"
          @click="addOwner"
        >
          <i class="mr-1 ion ion-ios-add-circle" />
          Add owner
        </cp-button>
        <cp-button
          size="lg"
          @click="submitData()"
        >
          <i class="mr-1 ion ion-ios-save" />
          Save wallet
        </cp-button>
      </b-card-footer>
    </div>
  </b-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CpInputContainer from '@/components/common/cpInputContainer';
import CpInput from '@/components/common/standalone-components/inputs/cp-input';
import CpButton from '@/components/common/standalone-components/cp-button';
import CpTabularHeaders
  from '@/pages/_idIssuer/configuration/fundraise-token/manage-token/components/common/tabular-headers';
import { i18n } from '@/i18n';

export default {
  name: 'CpMultiSigCard',
  components: { CpTabularHeaders, CpButton, CpInput, CpInputContainer },
  props: {
    id: {
      type: String,
      default: '',
    },
    address: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: 'Pending',
    },
    threshold: {
      type: Number,
      required: true,
    },
    deploymentStatus: {
      type: String,
      default: 'initial',
    },
    owners: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      multiSigWalletId: null,
      multiSigHeaders: [
        { title: this.$t('manageToken.manageRoles.headers.walletAddress'), size: 5 },
        { title: this.$t('manageToken.manageRoles.headers.comment'), size: 6 },
      ],
      multiSigFields: [
        { key: 'address', thStyle: { width: '45%' }, label: '' },
        { key: 'comment', thStyle: { width: '45%' }, label: '' },
        { key: 'actions', thStyle: { width: '10%' }, label: '' },
      ],
    };
  },
  computed: {
    ...mapState('configToken', ['deploymentToken']),
    walletId: {
      get() {
        return this.multiSigWalletId || this.id;
      },
      set(newValue) {
        this.multiSigWalletId = newValue;
      },
    },
    displayedAddress() {
      return this.address === '' ? 'pending' : this.address;
    },
    displayedVariant() {
      switch (this.deploymentStatus) {
        case 'initial':
          return 'secondary';
        case 'success':
          return 'success';
        case 'failure':
          return 'danger';
        default:
          return 'warning';
      }
    },
    isReadOnly() {
      return this.deploymentStatus !== 'initial';
    },
  },
  methods: {
    ...mapActions('configToken', ['addMultiSigWallet', 'getAllMultiSigWallets']),
    addOwner() {
      this.owners.push({ address: '', comment: '' });
    },
    removeOwner(index) {
      this.owners.splice(index, 1);
    },
    async submitData() {
      const isFormValid = this.$refs.multiSigForm.validateForm();
      const areOwnersValid = await this.validateOwners();

      if (isFormValid && areOwnersValid) {
        this.loading = true;
        this.saveWallet()
            .finally(() => {
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
              this.$emit('submitError', err);
            });
      } else {
        this.$emit('submitError', i18n.t('store.toaster.configToken.manageToken.invalidOwnerAddressField'));
      }
    },
    async validateOwners() {
      return this.checkForDuplicatedOwners();
    },
    checkForDuplicatedOwners() {
      return new Set(this.owners.map(item => item.address)).size === this.owners.length;
    },
    async addMultiSigWalletCall() {
      const request = {
        id: this.walletId,
        walletName: this.title,
        threshold: this.threshold,
        owners: this.owners,
      };
      return this.addMultiSigWallet({ deploymentId: this.deploymentToken.id, wallet: request });
    },
    async saveWallet() {
      this.walletId = await this.addMultiSigWalletCall();
      return this.getAllMultiSigWallets({ deploymentId: this.deploymentToken.id });
    },
  },
};
</script>

<style scoped type="scss">
.loader {
  position: absolute;
  top: 45%;
  left: 45%;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}

.multi-sig-card {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 35rem;
  -ms-flex: 1 1 35rem;
  flex: 1 1 35rem;
  margin: 10px;
  max-width: 40rem;
}

.card-footer-align {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.form-scroll {
  position: relative;
  height: 15rem;
  overflow: auto;
  display: block;
}

.status-badge {
  position: absolute;
  top: 3%;
  right: 10%;
  padding: 10px;
}

.remove-icon {
  vertical-align: middle;
  padding: 15px 0;
  cursor: pointer;
}

.card-sub {
  display: flex;
  flex-direction: column;
}

.card-sub > * {
  margin: 1px 0;
}

.card-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
</style>
